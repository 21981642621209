import { Hosting, Office365, Exchange, Zimbra } from '../assets-icon-svg/icons';

const webIcons = {
  Hosting,
  Office365,
  Exchange,
  Zimbra,
};

export default webIcons;
